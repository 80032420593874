<template>
  <div class="flex relative">
    <left-bar wrapBg="onboarding-bg" topBlockBorderColor="border-white"/>
    <div class="content w-full mx-12 main-content min-h-screen pt-12">
      <FeatureNav headerBorder="border-white" headerBg="onboarding-bg" :onboarding="true"/>
      <router-view />
    </div>
    <ProfileNav :onboarding="true"/>
  </div>
</template>

<script>
  import FeatureNav from '@/components/FeatureNav';
  import ProfileNav from '@/components/ProfileNav'
  import LeftBar from '@/components/LeftBar.vue';

  export default {
    components: {
      FeatureNav, 
      ProfileNav, 
      LeftBar
    },
  }
</script>

<style lang="scss" scoped>

</style>